<template>
  <FocusTrap>

  </FocusTrap>

</template>

<script>
import moment from 'moment-timezone'
import { userService } from '@/store/auth-header.js'
import { store } from '@/store/store.js'
export default {
  name: 'PartyStatement',
  store,
}
</script>

<style scoped>

</style>
